import React, { useReducer, useEffect, useState } from 'react';
import axios from 'axios'
import { Oval } from 'react-loader-spinner'
import { url } from "../components/Functions.js"

const CustomizationContext = React.createContext();

const initialState = false

const reducer = (state, action) => {
    switch (action.type) {
        case 'setData':
            return {
                ...action.payload
            };
        case 'setVisitante':
            return {
                ...state,
                register: false,
                visitante: {
                    ...state.visitante,
                    ...action.payload,
                },
            };
        case 'removeVisitante':
            const { visitante, ...restOfState } = state;
            return {
                ...restOfState,
            };
        case 'setRegister':
            return {
                ...state,
                register: !state.register,
            }
        case 'setApp':
            if (action.payload) {
                action.payload.isConnected = false
                return {
                    ...state,
                    data: action.payload,
                    currentApp: action.index,
                }
            } else {
                return {
                    ...state,
                    currentApp: action.index,
                }
            }
        default:
            return state;
    }
};

const CustomizationProvider = ({ children }) => {
    const [dataApplication, dispatch] = useReducer(reducer, initialState);
    const [showTokenScreen, setShowTokenScreen] = useState(false)
    const [updateDataApplication, setUpdateDataApplication] = useState(0)
    const [blockScreen, setBlockScreen] = useState(false)
    const [fontLoaded, setFontLoaded] = useState(false)
    const [styleVersion, setStyleVersion] = useState(0)
    const [token, setToken] = useState(false)
    const [error, setError] = useState(false)
    const [isOpen, setIsOpen] = useState()
    const [miniLoader, setMiniLoader] = useState(false)
    const [loading, setLoading] = useState(false);

    function setApp(index) {
        console.log('setApp')
        dispatch({ type: 'setApp', payload: dataApplication.apps[index], index: index });
    }

    function handleExit() {
        if ((dataApplication.apps.length > 1 && dataApplication.currentApp >= 0) || dataApplication.presenter) {
            dispatch({ type: 'setApp', index: undefined })
        } else {
            dispatch({ type: 'setRegister', payload: true })
            localStorage.setItem(dataApplication.token, "")
        }
    }

    useEffect(() => {
        const params = url()
        if (params.token || token) {
            if (token) {
                params.token = token
            }
            const startTime = Date.now(); // Record start time
            let encriptID = localStorage.getItem(params.token) ? JSON.parse(localStorage.getItem(params.token)) : false
            if (params.encryptedData && params.iv) {
                encriptID = { 'encryptedData': params.encryptedData, 'iv': params.iv }
            }
            axios
                .post(`/api/eventos/initialize/${params.token}`, { visitante_id: encriptID })
                .then(function (response) {
                    let data = response.data.message
                    let additionalDelay = 0
                    if (data && !data.customizacao?.removeLogoFlex) {
                        const fetchTime = Date.now() - startTime; // Calculate fetch duration
                        additionalDelay = 2000
                        additionalDelay = Math.max(additionalDelay - fetchTime, 0); // Adjust additional delay
                    }
                    if (response.data.error) {
                        // Server is down
                        setTimeout(() => {
                            setMiniLoader(false)
                            setShowTokenScreen(true)
                            setError('Houve um erro, tente novamente')
                        }, additionalDelay);
                    } else if (response.status === 202) {
                        // Server response
                        setTimeout(() => {
                            setMiniLoader(false)
                            setShowTokenScreen(true)
                            setError(data)
                        }, additionalDelay);
                    } else {
                        // if only 1 app in the event, automatically defines it was the current app and skips the app selection
                        if (data.apps.length === 1 && !params.presenter) {
                            data.data = data.apps[0]
                            data.currentApp = 0
                        }
                        // Defining users mode
                        if (params.presenter) {
                            if (params.presenter === data.codigo_presenter) {
                                data.presenter = true
                                data.register = true
                                localStorage.setItem(params.token, "")
                            } else {
                                setTimeout(() => {
                                    setShowTokenScreen(true)
                                    setError('Código de acesso ao modo apresentador inválido')
                                }, additionalDelay);
                            }
                        } else {
                            data.presenter = false
                            if (updateDataApplication === 0) {
                                // Remove user session if is not in database
                                if (data.visitante && data.visitante.visitante_id > 0 && !data.customizacao.noCache) {
                                    localStorage.setItem(params.token, JSON.stringify(encriptID));
                                    data.register = false
                                } else {
                                    data.register = true
                                    localStorage.setItem(params.token, "")
                                }
                            }
                        }
                        // Setting variables
                        data.params = params

                        // Search and set the form primary key
                        if (data.customizacao.form.fields.length > 0) {
                            data.customizacao.form.primary = []
                            // eslint-disable-next-line array-callback-return
                            Object.keys(data.customizacao.form.fields).map((key) => {
                                const objectToAdd = { 'label': data.customizacao.form.fields[key].label, 'inputID': data.customizacao.form.fields[key].inputID };
                                if (data.customizacao.form.fields[key].primary) {
                                    data.customizacao.form.primary.push(objectToAdd);
                                }
                            });
                        }
                        // Set data
                        dispatch({ type: 'setData', payload: data });
                        setTimeout(() => {
                            setToken(params.token)
                        }, additionalDelay);
                    }
                })
                .catch(function (error) {
                    setTimeout(() => {
                        setMiniLoader(false)
                        setShowTokenScreen(true)
                        setError('Servidor offline')
                    }, 1500);
                })
        } else {
            setTimeout(() => {
                setMiniLoader(false)
                setShowTokenScreen(true)
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataApplication])

    return (
        <CustomizationContext.Provider value={{
            dataApplication, dispatch, setUpdateDataApplication, token, setToken, error, setError,
            isOpen, setIsOpen, fontLoaded, setFontLoaded, showTokenScreen, loading, setLoading,
            blockScreen, setBlockScreen, setApp, miniLoader, setMiniLoader,
            setShowTokenScreen, handleExit, styleVersion, setStyleVersion
        }}>
            {children}
            {loading && <div className="loadingScreenProtection">
                <Oval
                    visible={true}
                    height="60"
                    width="60"
                    color={dataApplication && dataApplication.customizacao.primaryColor}
                    ariaLabel="oval-loading"
                /></div>}
        </CustomizationContext.Provider>
    );

};

export {
    CustomizationContext,
    CustomizationProvider
};
